import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Closing Commenting Early`}</h1>
    <ul>
      <li parentName="ul">{`Closing commenting early helps to manage `}<a parentName="li" {...{
          "href": "/sparsity",
          "title": "sparsity"
        }}>{`sparsity`}</a>{` in the `}<a parentName="li" {...{
          "href": "/sparse-matrix",
          "title": "sparse matrix"
        }}>{`sparse matrix`}</a>{` which is the `}<a parentName="li" {...{
          "href": "/polis-opinion-matrix",
          "title": "polis opinion matrix"
        }}>{`polis opinion matrix`}</a>{``}</li>
      <li parentName="ul">{`Sparsity simply means `}<a parentName="li" {...{
          "href": "/participants",
          "title": "participants"
        }}>{`participants`}</a>{` that did not vote on `}<a parentName="li" {...{
          "href": "/comments",
          "title": "comments"
        }}>{`comments`}</a>{``}</li>
      <li parentName="ul">{`Comments submitted right before the end of the `}<a parentName="li" {...{
          "href": "/conversation",
          "title": "conversation"
        }}>{`conversation`}</a>{` might only have a few `}<a parentName="li" {...{
          "href": "/votes",
          "title": "votes"
        }}>{`votes`}</a>{` before the `}<a parentName="li" {...{
          "href": "/conversation",
          "title": "conversation"
        }}>{`conversation`}</a>{` is closed. Sparsity would be high in this case.`}</li>
      <li parentName="ul">{`Comments submitted at the beginning of the `}<a parentName="li" {...{
          "href": "/conversation",
          "title": "conversation"
        }}>{`conversation`}</a>{` would have an opportunity to be voted on by everyone, and would have lower `}<a parentName="li" {...{
          "href": "/sparsity",
          "title": "sparsity"
        }}>{`sparsity`}</a>{`.`}</li>
      <li parentName="ul">{`Consider a conversation open for two weeks:`}
        <ul parentName="li">
          <li parentName="ul">{`Conversation A is open. Comments are submitted up to the last minute, and have very few votes.`}</li>
          <li parentName="ul">{`Conversation B closes commenting after one week via `}<a parentName="li" {...{
              "href": "/conversation-configuration",
              "title": "conversation configuration"
            }}>{`conversation configuration`}</a>{`. All participants are asked to return now that the full set of comments is in the system. Votes accumulate on the comments already in the system.`}</li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      